.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
}
.row {
  width: 33%;
  background-color: rgb(229, 62, 62);
  margin-right: 3px;
  padding: 1.5rem;
  box-sizing: border-box;
  margin-bottom: 3px;
}
.bluedove_logo{
  width: 50%;
  height: 50;
  margin-left: 0px;
}
.section {
  background-color: #fff;
  margin: 3px;
  border-radius: 0.5rem;
}
.box {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}
.box-left {
  background-color: rgb(229, 62, 62);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 100px;
}
.box-right {
  width: calc(100% - 120px);
  align-items: center;
  /* padding: 0.5rem 1rem; */
  background-color: rgb(229, 62, 62);
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-left: 3px;
  font-size: 6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
}
header {
  /* margin-top: 1rem; */
  background-color: #fff;
  color: #0023b4;
  font-size: 3rem;
  font-weight: 700;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.2rem 2rem;
  text-align: center;
}

#logo {
  margin: 0 auto;
  width: 55px;
}
.dish-image {
  width: 100%;
  /* max-width: 300px; */
  height: 200px !important;
  padding: 10px;
  box-sizing: border-box;

  overflow: hidden;
  object-fit: cover;

  border-radius: 28px !important;
  text-align: center;
}
.footer {
  color: #0023b4;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 1rem;
  margin-top: 10px;
}
.no-data {
  text-align: center;
  font-size: 2rem;
  color: #0023b4;
  position: absolute;
  width: 100%;
  top: 40%;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-wrap: wrap !important;
  }

  .row {
    width: 100%;
  }
}
/*
0 : - #e53e3e
1 : - #dd6b20
2 : - #f6ad55
3 : - #48bb78
4 : - #38a169
 */

.color-0 {
  background-color: #e53e3e !important;
}
.color-1 {
  background-color: #dd6b20 !important;
}
.color-2 {
  background-color: #f6ad55 !important;
}
.color-3 {
  background-color: #48bb78 !important;
}
.color-4 {
  background-color: #38a169 !important;
}
.gap {
  width: 100%;
  height: 20px;
}
